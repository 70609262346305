import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/client/pb-front/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/styles/fonts.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/styles/fixed.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/shared/styles/content.scss");
;
import(/* webpackMode: "eager" */ "/home/app/client/pb-front/src/app/queries.scss");
